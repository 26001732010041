<template>
  <div>
    <loading
      v-model:active="is_loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
    <article class="message is-warning is-small" v-if="error_message">
      <div class="message-body">
        {{ error_message }}
      </div>
    </article>
    <div class="content">
      <ContentHeader title="発払受付履歴" />
      <div>
        <p>
          発送受付のキャンセルを希望する場合は、受付取消ボタンを選択してください。<br />
          ※発払（クレジットカード決済）済のもののみキャンセルできます。<br />
          ※投函済の商品はキャンセルできません。サポートにお問い合わせください。
        </p>
      </div>
      <table class="reception-table">
        <thead>
          <tr>
            <td></td>
            <td>日時</td>
            <td>事業者名</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(reception, index) in receptions" :key="index">
            <td>
              <button
                class="button is-rounded is-small is-submit"
                @click="toReceptionCancel(reception)"
              >
                受付取消
              </button>
            </td>
            <td>{{ reception.created_at }}</td>
            <td>{{ reception.service_name }}</td>
          </tr>
        </tbody>
      </table>
      <div class="history-footer">
        この画面を終了したい方はブラウザを閉じてください。
      </div>
    </div>
  </div>
</template>
<script>
import DeliveryboxReceptionRepository from "../repositories/DeliveryboxReceptionRepository"
import DateService from "../services/DateService"
import { Auth } from "aws-amplify"
import Loading from "vue-loading-overlay"
import ContentHeader from "../components/ContentHeader.vue"

export default {
  name: "ReceptionHistory",
  data() {
    return {
      is_loading: false,
      receptions: [],
      error_message: "",
    }
  },
  components: {
    Loading,
    ContentHeader,
  },
  async beforeMount() {
    this.$store.commit("clearCancelOrder")
    this.is_loading = true
    try {
      const receptions = await DeliveryboxReceptionRepository.getCancelableReception()
      this.receptions = receptions.map((reception) => {
        reception.created_at = DateService.formatDatetime(reception.created_at)
        return reception
      })
    } catch (error) {
      this.error_message =
        "エラーが発生しました。しばらく時間をおいて、最初から操作を行ってください。"
      return
    } finally {
      this.is_loading = false
    }
  },
  methods: {
    toReceptionCancel(reception) {
      this.$router.push({
        path: `/reception/gmo-payment/${reception.order_no}/cancel`,
      })
    },
  },
}
</script>

<style scoped>
.is-submit {
  background-color: #092C0B;
  color: white;
  font-weight: bold;
}
.is-submit:hover {
  background-color: #124215;
  color: white;
}
.is-submit:active {
  background-color: #124215;
  color: white;
}
.reception-table {
  border-bottom: 1px solid #dbdbdb;
  text-align: center;
  margin-top: 1rem;
}
.reception-table td {
  border: none;
  vertical-align: middle;
}
.reception-table thead tr {
  font-weight: bold;
  border-top: 1px solid #dbdbdb;
  border-bottom: 1px solid #dbdbdb;
}
.history-footer {
  padding-top: 200px;
}
</style>
