<template>
  <div class="content-head">{{ title }}</div>
</template>

<script>
export default {
  name: "ContentHeader",
  props: {
    title: String,
  },
}
</script>

<style>
.content-head {
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  padding-bottom: 20px;
}
</style>
